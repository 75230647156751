import moment from 'moment';
import {
  ReferenceContentTypes,
  Resource,
  ResourceReference,
} from '../interfaces/resource.interface';
import { On24Entry } from '../../webinars/interfaces/on24.interface';

export const getContentType = (resourceRef: ResourceReference) => {
  return resourceRef.contentTypeId as ReferenceContentTypes;
};

function stripHtml(html: string) {
  if (html) {
    return html.replace(/(<([^>]+)>)/gi, '');
  }

  return '';
}

export const parseResources = (resources: Resource[], webinars: On24Entry[]): Resource[] => {
  const parsedResources = resources.map((r) => {
    // Check linked resource and assign descriminate types for type inference
    if (r.resourceReference) {
      r.resourceReference.kind = getContentType(r.resourceReference);
      if (r.resourceReference.kind === 'mrkPage') {
        r.callToActionDestination = `/${r.resourceReference.url_pageUrl}/`;
      }
      if (r.resourceReference.kind === 'page') {
        r.callToActionDestination = `/${r.resourceReference.relativePath}/`;
      }
    }
    return r;
  });

  const parsedWebinars: Resource[] = webinars.map((w) => {
    let industryFilters: string[] = [];
    let topicFilters: string[] = [];
    let languageFilters: string[] = [];

    if (w.filtergroups) {
      const industryFilterGroup = w.filtergroups.find((fg) => fg.name.toLowerCase() === 'industry');

      if (industryFilterGroup) {
        industryFilters = industryFilterGroup.filters.map((f) => f.name);
      }

      const topicFilterGroup = w.filtergroups.find((fg) => fg.name.toLowerCase() === 'topic');

      if (topicFilterGroup) {
        topicFilters = topicFilterGroup.filters.map((f) => f.name);
      }
      const languageFilterGroup = w.filtergroups.find((fg) => fg.name.toLowerCase() === 'language');

      if (languageFilterGroup) {
        languageFilters = languageFilterGroup.filters.map((f) => f.name);
      }
    }

    // Update resourceTitle and callToAction
    let resourceTypeCustom: string = '';
    let callToActionCustom:
      | 'Learn More'
      | 'Download PDF'
      | 'Download Image'
      | 'Watch Video'
      | 'Register'
      | 'Register Now!'
      | 'Watch Now';
    if (moment().isSameOrBefore(moment(w.liveend))) {
      resourceTypeCustom = 'Webinar - Live';
      callToActionCustom = 'Register Now!';
    } else {
      resourceTypeCustom = 'Webinar - On-demand';
      callToActionCustom = 'Watch Now';
    }

    return {
      id: w.id,
      title: w.title,
      externalLinkUrl: w.url,
      displaytimezonecd: w.displaytimezonecd,
      livestart: w.livestart,
      goodafter: w.goodafter,
      filters: {
        industries: industryFilters,
        topics: topicFilters,
        resourceTypes: [],
        language: languageFilters,
      },
      industry: industryFilters,
      topic: topicFilters,
      language: languageFilters,
      description: stripHtml(w.description),
      resourceType: 'Webinar',
      resourceTypeCustom: resourceTypeCustom,
      callToAction: callToActionCustom,
      orderDate: w.goodafter,
      resourceTitle: w.title,
      resourceMedia: {},
    };
  });

  return [...parsedResources, ...parsedWebinars];
};

export const removeHiddenResources = (resources: Resource[]): Resource[] => {
  return resources.filter((r) => {
    return !r.hideFromResourceHub;
  });
};
